const characterSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "character",
  title: "Character",
  description: "Represents a single character.",
  type: "object",
  properties: {
    armorclass: { type: "number" },
    background: { type: "object" },
    class: { type: "string" },
    equipment: { type: "array" },
    features: { type: "object" },
    hitdice: { type: "string" },
    hitpoints: { type: "number" },
    level: { type: "number" },
    name: { type: "string" },
    proficiency: { type: "object" },
    race: { type: "string" },
    sources: { type: "object" },
    speed: { type: "object" },
    spells: { type: "object" },
    stats: { type: "object" },
    weapons: { type: "object" },
  },
  required: [
    "armorclass",
    "background",
    "class",
    "equipment",
    "features",
    "hitdice",
    "hitpoints",
    "level",
    "name",
    "proficiency",
    "race",
    "sources",
    "spells",
    "stats",
    "weapons",
  ],
  additionalProperties: false,
};

module.exports = characterSchema;
