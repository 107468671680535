export const collaborators = [
  "grahambleon",
  "vanadgir",
  "br-ndt"
];

export const contributors = [
  "VoX",
  "sammcgrail",
  "Pete-Eichman"
];